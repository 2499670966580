import React, { useRef } from 'react';
import Layout from '../components/layout';
import Box, { BoxTitle, BoxParagraph, BoxButton, Bibliobox} from '../elements/box';

function Deontologie() {

  const scrollToRef = (ref) => window.scrollTo({
    top: ref.current.offsetTop - 50,
    behavior: 'smooth'
  });

  const obligationRef = useRef(null);
  const devoirsRef = useRef(null);
  const rapportsRef = useRef(null);
  const applicationRef = useRef(null);

  return(
    <Layout>
      <Box>
        <BoxTitle title="Déontologie" />
        <BoxParagraph>
          <p>Code de déontologie du Syndicat National des Praticiens en Psychothérapie</p>
        </BoxParagraph>
        <div className="columns">
          <div className="column">
            <BoxButton functionClick={() => scrollToRef(obligationRef)}>
              <BoxParagraph>
                <p><b>Obligations</b></p>
              </BoxParagraph>
            </BoxButton>
          </div>
          <div className="column">
            <BoxButton functionClick={() => scrollToRef(devoirsRef)}>
              <BoxParagraph>
                <p><b>Devoirs</b></p>
              </BoxParagraph>
            </BoxButton>
          </div>
          <div className="column">
            <BoxButton functionClick={() => scrollToRef(rapportsRef)}>
              <BoxParagraph>
                <p><b>Rapports aux autres professionnels</b></p>
              </BoxParagraph>
            </BoxButton>
          </div>
          <div className="column">
            <BoxButton functionClick={() => scrollToRef(applicationRef)}>
              <BoxParagraph>
                <p><b>Application</b></p>
              </BoxParagraph>
            </BoxButton>
          </div>
        </div>
      </Box>

      <Box background="primary-light" reference={obligationRef} >
        <BoxTitle title="I - Obligations générales du psychothérapeute" />
        <div className="columns">
          <div className="column">
            <Bibliobox
              background=""
              title="Art I - 1"
              description="Formation professionnelle. Le psychothérapeute a une formation professionnelle approfondie théorique et pratique apte à créer une compétence de praticien."
            />
          </div>
          <div className="column">
            <Bibliobox
              background=""
              title="Art I - 2"
              description="Processus thérapeutique personnel. Il est passé lui-même par un processus psychothérapeutique approfondi. Cette démarche personnelle est distincte de sa formation, bien qu'elle y participe fondamentalement."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Bibliobox
              background=""
              title="Art I - 3"
              description="Formation continue. Sa formation et son développement personnel doivent faire l'objet d'une constante régénération tout au long de sa carrière."
            />
          </div>
          <div className="column">
            <Bibliobox
              background=""
              title="Art I - 4"
              description="Contrôle et supervision. Le psychothérapeute se maintient dans un système de supervision ou de contrôle de sa pratique par un tiers qualifié."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Bibliobox
              background=""
              title="Art I - 5"
              description="Indépendance professionnelle. Le psychothérapeute ne doit pas accepter des conditions de travail qui porteraient atteinte à son indépendance professionnelle et, notamment, qui l'empêcherait d'appliquer les principes déontologiques énoncés ici."
            />
          </div>
          <div className="column">
            <Bibliobox
              background=""
              title="Art I - 6"
              description="Attitude de réserve. Le psychothérapeute, conscient de son pouvoir, s'engage à une attitude de réserve. Il prend garde aux conséquences directes ou indirectes de ses interventions et, entre autres, à l'utilisation qui pourrait en être faite par des tiers."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Bibliobox
              background=""
              title="Art I - 7"
              description="Information sur son exercice. Toute information du public (articles, publications, émissions radio ou télédiffusées, enseignes, annonces payantes, conférences, documents pédagogiques, etc...) doit être faite dans une position de réserve et de décence sur la personnalité du psychothérapeute, sur la nature des soins qu'il fournit et sur les résultats escomptés de la psychothérapie. Le psychothérapeute n'utilisera pas ses clients à des fins médiatiques."
            />
          </div>
          <div className="column">
            <Bibliobox
              background=""
              title="Art I - 8"
              description="Appartenance au syndicat. Seuls les membres titulaires peuvent se prévaloir de leur appartenance au syndicat. "
            />
          </div>
        </div>
      </Box>

      <Box reference={devoirsRef} >
        <BoxTitle title="II - Devoirs du psychothérapeute vis-à-vis de ses patients"/>
        <div className="columns">
          <div className="column">
            <Bibliobox
              title="Art II - 1"
              description="Qualité des soins : Dès lors qu'il s'est engagé dans un contrat thérapeutique avec une personne, le psychothérapeute s' engage à lui donner personnellement les meilleurs soins."
            />
          </div>
          <div className="column">
            <Bibliobox
              title="Art II - 2"
              description="Appel à un tiers : A cet effet, et s'il l'estime utile, il fait appel à la collaboration de tiers."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Bibliobox
              title="Art II - 3"
              description="Devoir de réserve : Conscient de la relation très spécifique qui le lie à ses patients, le psychothérapeute observe une attitude de réserve en toutes circonstances."
            />
          </div>
          <div className="column">
            <Bibliobox
              title="Art II - 4"
              description="Abstinence sexuelle : Le psychothérapeute s'abstient de toutes relations sexuelles avec ses patients ainsi qu'avec ses étudiants en formation et collègues en supervision."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Bibliobox
              title="Art II - 5"
              description="Respect de l'individu : Le psychothérapeute respecte l'intégrité et les valeurs propres du patient dans le cadre du processus de changement."
            />
          </div>
          <div className="column">
            <Bibliobox
              title="Art II - 6"
              description="Responsabilité du client : Le psychothérapeute se doit d'attirer l'attention du patient sur sa responsabilité propre et sur la nécessité d'une coopération active et permanente de ce dernier."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Bibliobox
              title="Art II - 7"
              description="Sécurité physique : Dans le cadre de sa pratique, le psychothérapeute instaure une règle de non-violence sur les personnes et les biens."
            />
          </div>
          <div className="column">
            <Bibliobox
              title="Art II - 8"
              description="Honoraires : Chaque psychothérapeute fixe lui-même ses honoraires en conscience."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Bibliobox
              title="Art II - 9"
              description="Secret professionnel : Le psychothérapeute est soumis aux règles usuelles du secret professionnel qui s'étend à tout ce qu'il a vu, entendu ou compris au cours de sa pratique."
            />
          </div>
          <div className="column">
            <Bibliobox
              title="Art II - 10"
              description="Garantie de l'anonymat : Le psychothérapeute prend toutes les précautions nécessaires pour préserver l'anonymat des personnes qui le consultent ou l'ont consulté."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Bibliobox
              title="Art II - 11"
              description="Secret professionnel et co-thérapie : Si des raisons thérapeutiques nécessitent la collaboration avec une personne donnant des soins au thérapisant, le psychothérapeute ne peut partager ses informations qu'avec l'accord du patient. Cet accord est implicitement donné dans un processus de co-thérapie."
            />
          </div>
          <div className="column">
            <Bibliobox
              title="Art II - 12"
              description="Groupe : anonymat et discrétion : En séance collective, le psychothérapeute prescrit aux membres du groupe une obligation de secret quant à l'identité des participants et de discrétion sur le déroulement des séances."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Bibliobox
              title="Art II - 13"
              description="Protection des participants : En séance de groupe, le psychothérapeute interdit le passage à l'acte sexuel entre les participants et tout acte physique dommageable aux personnes et aux biens."
            />
          </div>
          <div className="column">
            <Bibliobox
              title="Art II - 14"
              description="Liberté l'engagement du psychothérapeute : Le psychothérapeute n'est jamais tenu de s'engager dans un processus de soins psychothérapiques."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Bibliobox
              title="Art II - 15"
              description="Continuité : Le psychothérapeute se doit d'assurer la continuité de l'engagement psychothérapique ou d'en faciliter les moyens."
            />
          </div>
          <div className="column">
            <Bibliobox
              title="Art II - 16"
              description="Choix du psychothérapeute : Le psychothérapeute respecte et facilite le libre choix de son thérapeute par le thérapisant."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
          <Bibliobox
              title="Art II - 17"
              description="Changement de thérapeute : Le psychothérapeute est conscient des liens spécifiques mis en place par une thérapie précédemment engagée avec un confrère. Dans le cas d'une consultation en vue de changer de thérapeute, il facilitera l'analyse de la difficulté qui a surgi. "
            />
          </div>
        </div>

      </Box>

      <Box background="primary-light" reference={rapportsRef} >
        <BoxTitle title="III – Rapports du psychothérapeute à ses confrères, aux autres professionnels de la santé et aux institutions" />
        <div className="columns">
          <div className="column">
            <Bibliobox
              background=""
              title="Art III - 1"
              description="Information déontologique. Le code de déontologie des praticiens en psychothérapie est public."
            />
          </div>
          <div className="column">
            <Bibliobox
              background=""
              title="Art III - 2"
              description="Personnel adjoint. Le psychothérapeute fait respecter le présent code par les personnels dont il est amené à se faire entourer."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Bibliobox
              background=""
              title="Art III - 3"
              description="Appartenance institutionnelle : Le fait pour un psychothérapeute, d'être lié à un centre de soins, de formation, à un lieu de vie ou d’appartenir à des structures sociales ou associatives ne saurait porter atteinte à l'application des présentes règles déontologiques."
            />
          </div>
          <div className="column">
            <Bibliobox
              background=""
              title="Art III - 4"
              description="Contrôleurs, superviseurs, formateurs : Les psychothérapeutes exerçant des contrôles, supervisions ou activités didactiques doivent se faire dûment identifier par leurs groupes respectifs."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Bibliobox
              background=""
              title="Art III - 5"
              description="Règles de confraternité : Aucune pratique ni institution ne pouvant prétendre à l'exclusivité ou à la primauté sur les autres dans la compétence psychothérapeutique, le praticien est tenu au devoir de réserve par rapport à ses confrères."
            />
          </div>
          <div className="column">
            <Bibliobox
              background=""
              title="Art III - 6"
              description="Rapport à la médecine : Conscient de la spécificité de la psychothérapie et de celle de la médecine, le psychothérapeute invite son patient à s'entourer de toutes les garanties de cette dernière."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <Bibliobox
              background=""
              title="Art III - 7"
              description="Utilisation du nom : Nul n’a le droit dans un texte informatif ou publicitaire, d’utiliser les nom et titres d’un psychothérapeute sans son autorisation expresse et son accord écrit."
            />
          </div>
        </div>
      </Box>

      <Box reference={applicationRef} >
        <BoxTitle title="IV- Application du code de déontologie" />
        <div className="columns">
          <div className="column">
            <Bibliobox
              title="Art IV - 1"
              description="Rôle de la commission déontologique : En matière de déontologie, la commission interne au Syndicat (Syndicat national des praticiens en psychothérapie) a un rôle d'information, de prévention, de conseil et d'examen des requêtes."
            />
          </div>
          <div className="column">
            <Bibliobox
              title="Art IV - 2"
              description="Manquements aux règles déontologiques : A la demande de l'intéressé, sur plainte interne ou externe, la commission de déontologie est à la disposition du psychothérapeute ou du plaignant pour examiner cette plainte."
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Bibliobox
              title="Art IV - 3"
              description="Sanctions : La commission de déontologie, statuant sur la valeur du manquement aura pouvoir de délivrer dans l'ordre : un rappel à l'ordre, un avertissement ou un blâme, ou de recommander l'exclusion temporaire ou définitive du psychothérapeute.
              En ce qui concerne l'exclusion temporaire ou définitive, la recommandation de la commission devra être entérinée par un vote du conseil d'administration à la majorité des trois quarts. Quelles que soient les instances, elles auront obligation d'entendre le psychothérapeute intéressé et ses défenseurs éventuels."
            />
          </div>
          <div className="column">
            <Bibliobox
              title="Art IV - 4"
              description="Procédure : Sur proposition de la commission de déontologie, le conseil d'administration établit un règlement de procédure détaillé pour l'application des articles IV/2 et IV/3, concernant les manquements et les sanctions."
            />
          </div>
        </div>
      </Box>
      
    </Layout>
  )
}

export default Deontologie;